@import "bourbon/bourbon";

// Colors
$primary_color: #136782;
$blue: #136782;
$dark-blue: #10556c;
$dark-blue-gray: #8E95A0;
$light-blue-gray: #E3E7ED;
$white: white;

// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,900,300italic,400italic,900italic);
$lato: 'Lato', "Helvetica Neue", Helvetica, Arial, sans-serif;
$brandon: "brandon-grotesque", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;


body {
	-webkit-font-smoothing: antialiased;
	min-width:1000px;
	height: 100%;
	font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding-bottom: 300px;

	&.sidebar {
		#header{
			.container {
			  width: 100%;
			  max-width: none !important;
			}
		}
		#main_content {
			margin: 60px 0 0 220px;
			min-width: 750px;
			height:100%;
			.container {
			  width: 100%;
			  max-width: none !important;
			}
		}
	}
	&.deprecated {
		#header {
			background: #999;

		}
		#sidebar {
			.nav {
				> li {
					> a {
						color:#999;
					}
					&.active {
						a {
							background:#999;
							color:#fff;
						}
						.nav {
							li {
								&.active {
									a {
										border-left:2px solid #999;
									}
								}
							}
						}
					}
				}
			}
		}
		#sections {
			background-color: #4D4D4F;
			.section {
				.section-description {
					&:before {
						background-color: #e4e8eb;
					}
				}
				.section-example {
					&:before {
						background: -webkit-linear-gradient(left,#555,rgba(80,80,80,0));
						background: -moz-linear-gradient(left,#555,rgba(80,80,80,0));
						background: -o-linear-gradient(left,#555,rgba(80,80,80,0));
						background: linear-gradient(right,#555,rgba(80,80,80,0));
					}
					&:after {
						background: -webkit-linear-gradient(left,#aaa,rgba(170,170,170,0));
						background: -moz-linear-gradient(left,#aaa,rgba(170,170,170,0));
						background: -o-linear-gradient(left,#aaa,rgba(170,170,170,0));
						background: linear-gradient(right,#aaa,rgba(170,170,170,0));
					}
				}
			}
		}
	}
}

#header {
	border-bottom: 1px solid $dark-blue;
	background: $primary_color;
	color: #fff;
	width: 100%;
	height: 60px;
	position:fixed;
	top:0;
	z-index:30;

  img {
		width: 200px;
		margin-top: -3px;
	}
	.navbar-brand {
		padding:10px 15px;
	}
	.navbar-nav {
		border-right: 1px solid rgba(255,255,255,0.2);
		margin-left: 30px;
		> li {
			border-left: 1px solid rgba(255,255,255,0.2);
			&.active {
				font-weight: bold;

				> a {
					background-color: $dark-blue;
				}
			}
			> a {
				color:#fff;
				padding: 20px 30px 19px;
				&:hover {
					background-color: rgba(255,255,255,0.1) !important;
				}
			}
		}
	}
}


#sidebar {
	background: white;
	border-right: 1px solid #dadfe5;
	bottom: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	top: 60px;
	width: 220px;
	z-index: 25;
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	padding-top:40px;
	position:fixed;
	top:60px;

	.nav {
		margin-bottom:40px;
		list-style: none;
		.header {
			color: #7a8e99;
			font-family: $brandon;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 400;
			padding: 0;
			list-style: none;
			letter-spacing: 1px;
			margin-bottom: 10px;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			text-indent: 20px;

			.label-default {
				background-color: #ccc;
				padding: 2px 5px;
				font-size: 10px;
			}
		}
		> li {
			text-indent: 20px;
			a {
				color: $primary_color;
				border-left:2px solid transparent;
			}
			&.active {
				a {
					background: $primary_color;
					color: #fff;
				}
				.nav {
					margin:5px 0 0 20px;
					display:block;
					li {
						text-indent: 0;
						a {
							background: transparent;
							color: #999;
							padding-left: 15px;
						}
						&.active {
							a {
								border-left:2px solid $primary_color;
							}
						}
					}
				}
			}
		}
		ul {
			display:none;
		}
	}

	.nav>li>a {
		padding: 3px 0;
	}
	&.affix, &.affix-bottom {
		position:fixed;
		top:60px;
		left:0;
	}
}

#main_content {
	margin-top:60px;
	.highlight {
		background: #2b303a;
		code {
			color: #bfc4cd;
		}
	}
}

#sections {
	background-color: #2b303a;
	margin-left: 50%;
	position: relative;
	.section {
		padding-top:45px;
		position: relative;
		.section-anchor {
			display: block;
			height: 0;
		}
		.section-inner {
			clear: left;
			margin-left: -100%;
			padding-top: 45px;
			position: relative;
			overflow: hidden;
			.section-description {
				background-color:#fff;
				color: #444;
				float: left;
				padding: 25px 30px;
				position: relative;
				width: 50%;
				z-index: 15;
				h2 {
						small {
							font-size: 12px;
							display: block;
							margin-top: 5px;
						}
					}
				&:before {
					background-color: #e4e8eb;
					content: "";
					display: block;
					height: 1px;
					left: 0;
					position: absolute;
					right: 1px;
					top: 10px;
				}
			}
			.section-example {
				margin-left: 50%;
				padding: 38px 30px 0;
				position: relative;
				width: 50%;
				color:#c1c6ce;
				&:before {
					background: -webkit-linear-gradient(left,#1c1f26,rgba(28,31,38,0));
					background: -moz-linear-gradient(left,#1c1f26,rgba(28,31,38,0));
					background: -o-linear-gradient(left,#1c1f26,rgba(28,31,38,0));
					background: linear-gradient(right,#1c1f26,rgba(28,31,38,0));
					content: "";
					display: block;
					height: 1px;
					left: 0;
					position: absolute;
					right: 0;
					top: 10px;
				}
				&:after {
					content: "";
					display: block;
					height: 1px;
					left: 0;
					position: absolute;
					right: 0;
					background: -webkit-linear-gradient(left,#45494c,rgba(69,73,76,0));
					background: -moz-linear-gradient(left,#45494c,rgba(69,73,76,0));
					background: -o-linear-gradient(left,#45494c,rgba(69,73,76,0));
					background: linear-gradient(right,#45494c,rgba(69,73,76,0));
					top: 9px;
				}
			}
		}
		&:first-child, &:nth-child(3) {
			padding-top: 0;
		}
		&:first-child {
			border-top: 1px solid rgba(255,255,255,0.25);

			.section-inner {
				.section-description {
					&:before {
						display:none;
					}
				}
				.section-example {
					&:before, &:after {
						display:none;
					}
				}
			}
		}
	}
}

.post{
	padding-bottom:20px;
	margin-bottom:20px;
	border-bottom:1px solid #dedede;
	h3 {
		.date {
			color:#999;
			font-size: 16px;
		}
	}
	.label {
		// width:55px;
		display: inline-block;
		margin-right:5px;
	}

}
@media screen and (max-width: 1400px) {
	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
}

#footer {
  margin-top: 70px;
  background: $dark-blue;
  color: white;
  padding: 20px 0;
  transform: translateY(100%);
  @include position(fixed, auto 0 4px 0);
  z-index: 2000;
  transition: bottom 0.2s, transform 0.2s;

  &.no-transition {
  	@include transition(none);
  }

  .handle {
  	overflow: hidden;
  	@include position(absolute, auto auto 100% 50%);
  	@include transform(translateX(-50%));
  	background: $dark-blue;
		border-radius: 4px 4px 0 0;
		display: inline-block;
		padding: 0 30px;
		cursor: pointer;
  }

  h5 {
    font-family: $brandon;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    color: $dark-blue-gray;
  }
  ul {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;

    a {
      display: inline-block;
      color: $light-blue-gray;
      font-weight: 300;
      padding: 2px 0;
      font-size: 14px;
      text-decoration: none;
      @include transition(color 0.2s);

      &:hover {
        color: $white;
      }
    }
    &#social {
      margin-top: 8px;
      margin-bottom: 42px;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 10px;

        a {
          padding: 0;
          font-size: 24px;
        }
      }
    }
  }
  .copyright {
    p {
      font-weight: 300;
      color: $dark-blue-gray;
      font-size: 13px;
    }
  }
}

body.footer-expanded {
	#sidebar {
		bottom: 229px;
		@include transition(bottom, 0.2s);

	}
	#footer {
		bottom: 0;
		transform: translateY(0);
		transition: bottom 0.2s, transform 0.2s;

	}
}

.email-subscribe-iframe {
	margin: 0 -5px;
}
